<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

:root {
  min-height: 100vh;
  background-color: #EEF4FA;
  color: rgba(51, 51, 51, 1);
}

</style>
